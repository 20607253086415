import { getValidationMessages } from '../../services/error-handler/helpers/get-validation-messages';
import type { ApiErrorResponseBody } from '../responses/api-error-response';

export class ApiError implements Error {
    name = 'ApiError';
    message: string;

    public readonly code: any;
    public readonly type: string;
    public readonly fields: any;
    public readonly url: any;
    public readonly validationErrorMessages: any[];

    constructor(attributes: ApiErrorResponseBody, url?: string) {
        this.message = attributes.message;
        this.code = attributes.code;
        this.type = attributes.type;
        this.fields = attributes.fields;
        this.url = url;

        this.validationErrorMessages = getValidationMessages({ fields: this.fields });

        if (this.message === 'Some fields are invalid') {
            this.message = summariseValidationMessages(this.validationErrorMessages);
        }
    }

    is4xx(): boolean {
        return this.code >= 400 && this.code < 500;
    }

    is5xx(): boolean {
        return this.code >= 500 && this.code < 600;
    }

    static instanceOf(test: any) {
        return test instanceof ApiError || test.name === 'ApiError';
    }
}

function summariseValidationMessages(messages: string[]): string {
    if (messages.length === 0) {
        return 'Some fields are invalid';
    }

    if (messages.length === 1) {
        return messages[0];
    }

    return `${messages[0]} (and ${messages.length - 1} more)`;
}
