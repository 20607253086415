import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import type { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import type { AppSelectOption } from '@shared/form-fields/options/select-option';
import type { AppSelectChange } from '@shared/form-fields/select/app-select-change';
import { CompareWith } from '@shared/form-fields/select/compare-with';
import { isPresent } from '@shared/utils/helpers';
import { isObject } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

@Directive()
export abstract class BaseSelectFormFieldComponent<T = any> extends AbstractFormFieldComponent<T> implements OnChanges {
    @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;

    @Input() fetchFromApi = true;

    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() compareWith: CompareWith<T>;
    @Input() protected objectEqualityKey = 'id';
    @Input() searchEnabled = false;
    @Input() searchPlaceholder = 'Search';

    @Input() exclude: T[];
    @Input() only: T[];

    @Output() selectionChange = new EventEmitter<AppSelectChange<T>>();
    @Output() apiOptionsChange = new EventEmitter<AppSelectOption<T>[]>();
    @Output() fetchingChange = new EventEmitter<boolean>();

    protected _options: AppSelectOption<T>[];
    protected _apiOptions: AppSelectOption<T>[];

    private _fetching = false;

    get fetching(): boolean {
        return this._fetching;
    }

    set fetching(value: boolean) {
        this._fetching = value;

        this.fetchingChange.emit(value);
    }

    @Input() set options(value: AppSelectOption<T>[]) {
        this._options = value;
        this._apiOptions = null;

        this.writeValue(this.value);

        this.searchCtrl.setValue(this.searchCtrl.value);

        // Template won't update unless wrapped in requestAnimationFrame
        requestAnimationFrame(() => this.triggerFilter$.next(true));
    }

    get options(): AppSelectOption<T>[] {
        const options = this._apiOptions || this._options;

        if (options && this.only) {
            return options.filter(
                option => this.only.findIndex(
                    i => this.compareValueWith(i, option.value)
                ) > -1
            );
        }

        return options;
    }

    protected set apiOptions(options: AppSelectOption<T>[]) {
        this._apiOptions = options;

        if (this._selectedOption) {
            const option = this.options.find(o => this._compareWith(o, this._selectedOption));

            if (option) {
                const selectedOptionValue = this._selectedOption.value;

                if (isObject(selectedOptionValue) && isObject(option.value)) {
                    // Make sure any missing properties are added
                    this.writeValue({
                        ...this._selectedOption.value,
                        ...option.value,
                    });
                } else {
                    this.writeValue(option.value);
                }
            } else {
                this.writeValue(null);
            }

            this._selectedOption = option;
        }

        this.apiOptionsChange.emit(this.options);

        // Template won't update unless wrapped in requestAnimationFrame
        requestAnimationFrame(() => this.triggerFilter$.next(true));
    }

    protected _selectedOption: AppSelectOption<T>;

    get selectedOption(): AppSelectOption<T> {
        return this._selectedOption;
    }

    set selectedOption(value: AppSelectOption<T>) {
        this._selectedOption = value;

        this.value = value ? value.value : undefined;
    }

    get isDisabled() {
        return this.disabled || this._fetching;
    }

    // Search
    searchCtrl = new UntypedFormControl();

    // Allow the filtering to be triggered manually when options are updated
    triggerFilter$ = new BehaviorSubject<true>(true);
    filteredOptions$ = combineLatest([
        // Make sure the searchCtrl emits null to start with so combineLatest can begin emitting
        this.searchCtrl.valueChanges.pipe(startWith(null)),
        this.triggerFilter$
    ]).pipe(
        map(([query]) => this.filterOptions(this.options, query)),
        shareReplay(1)
    );

    isEmpty$: Observable<boolean> = this.filteredOptions$.pipe(
        map(options => !options?.length)
    );

    @Input() showTriggerDescription = true;

    constructor(
        element: ElementRef,
        renderer: Renderer2,
    ) {
        super(element, renderer);

        this._compareWith = this._compareWith.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['exclude']) {
            requestAnimationFrame(() => this.triggerFilter$.next(true));
        }
    }

    writeValue(value: T) {
        super.writeValue(value);

        if (isPresent(value)) {
            this._selectedOption = {
                label: value?.['presenter']?.['optionLabel'] ?? value?.['presenter']?.['title'],
                value,
                disabled: true
            };
        } else {
            this._selectedOption = undefined;
        }

        if (this.options) {
            if (this._selectedOption) {
                const selectedOption = this.options.find(o => this._compareWith(o, this._selectedOption));

                // We've found the given option in the options list
                if (selectedOption) {
                    this._selectedOption = selectedOption;
                } else {
                    // It may be a soft-deleted model, insert it into the options list and disable it
                    this.options.push(this._selectedOption);
                }
            } else if (value === null) {
                // There might an option with a null value
                const selectedOption = this.options.find(o => o.value === null);

                if (selectedOption) {
                    this._selectedOption = selectedOption;
                }
            }
        }
    }

    public _compareWith(a: AppSelectOption<T>, b: AppSelectOption<T>): boolean {
        if (this.compareWith) {
            return this.compareWith(a, b);
        }

        if (a === b) {
            return true;
        }

        if (a && b) {
            const aValue = a.value;
            const bValue = b.value;

            if (aValue === bValue) {
                return true;
            }

            if (aValue && bValue) {
                return (
                    typeof aValue === 'object'
                    && typeof bValue === 'object'
                    && aValue[this.objectEqualityKey] === bValue[this.objectEqualityKey]
                );
            }
        }

        return false;
    }

    private compareValueWith(aValue: T, bValue: T) {
        return aValue === bValue || (
            typeof aValue === 'object'
            && typeof bValue === 'object'
            && aValue[this.objectEqualityKey] === bValue[this.objectEqualityKey]
        );
    }

    clear($event?: MouseEvent) {
        super.clear($event);

        this._selectedOption = undefined;
    }

    isNotExcluded(option: AppSelectOption<T>): boolean {
        if (this.exclude?.length) {
            return this.exclude.findIndex(value => this._compareWith(option, { label: null, value })) === -1;
        }

        return true;
    }

    _focusSearchInput() {
        // Focus the search input
        requestAnimationFrame(() => this.searchInput?.nativeElement?.focus());
    }

    _onOptionSelected($event: MatSelectChange) {
        this.selectionChange.emit({
            source: $event.source,
            option: $event.value.value as T,
        });

        // Template won't update unless wrapped in requestAnimationFrame
        requestAnimationFrame(() => this.triggerFilter$.next(true));
    }

    filterOptions(
        options: AppSelectOption<T>[],
        search: string,
    ): AppSelectOption<T>[] {
        if (!options?.length) {
            return options;
        }

        if (this.exclude?.length) {
            options = options.filter(option => this.isNotExcluded(option));
        }

        if (!search?.length || !options?.length) {
            return options;
        }

        search = search.toLowerCase();

        return options.filter(
            option => option.label.toLowerCase().includes(search) || option?.description?.toLowerCase()?.includes(search)
        );
    }
}
