/* eslint-disable @angular-eslint/directive-selector */
import type { OnInit } from '@angular/core';
import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { country } from '../../config';

@Directive({
    selector: '[ifCountry]'
})
export class IfCountryDirective implements OnInit {
    private appCountry = country;
    private selectedCountries: string | string[] = [];
    private isHidden = true;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        this.updateView();
    }

    @Input()
    set ifCountry(countries: string | string[]) {
        this.selectedCountries = countries;
        this.updateView();
    }

    private updateView() {
        if (this.check()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private check(): boolean {
        const selectedCountries = this.selectedCountries;
        const appCountry = this.appCountry;

        if (!selectedCountries?.length || !appCountry?.length) {
            return false;
        }

        if (Array.isArray(selectedCountries)) {
            return selectedCountries.includes(appCountry);
        }

        return selectedCountries === appCountry;
    }
}
