import type { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '../../http/errors/api-error';

export function parseHttpErrorResponse(error: HttpErrorResponse) {
    const body = error.error;

    if (error.status == 413) {
        return new ApiError({
            message: 'The request content is too large',
            type: 'request_too_large',
            code: 413,
        });
    }

    if (body.error) {
        return new ApiError(body.error);
    }

    return body || error;
}

export function toApiError(error: HttpErrorResponse): ApiError | null {
    const body = error?.error;

    if (body?.error) {
        return new ApiError(body.error);
    }

    return null;
}
