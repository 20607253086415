<ng-container *ngIf="!successfulLoginResponse">
    <app-auth-form-card
        *ngIf="!showMfaChallenge"
        title="Sign in"
        subTitle="Enter your credentials to access the Management Portal or Care Circle">
        <form (ngSubmit)="login()">
            <div class="login-with">
                <div
                    class="sso"
                    *ngIf="ssoEnabled">
                    <button cll-outline-button>
                        <cll-icon cllButtonIcon>key</cll-icon>
                        Sign in with SSO
                    </button>
                </div>

                <div
                    class="login-with__divider"
                    *ngIf="ssoEnabled">
                    <div class="login-with__divider__line"></div>
                    <div class="login-with__divider__text">or</div>
                    <div class="login-with__divider__line"></div>
                </div>

                <div class="login-form">
                    <cll-form-field label="Email Address ">
                        <input
                            [formControl]="loginForm.controls.email"
                            autocomplete="username email"
                            autofocus="autofocus"
                            cllFormInput
                            data-testid="input:email"
                            type="email"/>
                    </cll-form-field>

                    <cll-form-field label="Password ">
                        <input
                            #password
                            [formControl]="loginForm.controls.password"
                            autocomplete="password"
                            cllFormInput
                            data-testid="input:password"
                            type="password"/>

                        <cll-password-toggle [input]="password" cllSuffix/>
                    </cll-form-field>

                    <div class="more-actions">
                        <ng-container *ngIf="showRememberMe">
                            <cll-checkbox
                                [formControl]="loginForm.controls.remember"
                                data-testid="checkbox:remember-me">
                                Remember me
                            </cll-checkbox>
                        </ng-container>

                        <p class="forgotten-password-container">
                            <a
                                class="forgotten-password-link"
                                [routerLink]="['/', 'auth', 'forgot-password']"
                                data-testid="link:forgotten-password">
                                Forgotten password?
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="loginRequest$ | async as request">
                <app-spinner *ngIf="request.fetching"/>

                <ng-container *ngIf="request.apiError as error">
                    <cll-panel
                        colour="error"
                        *ngIf="error.type === 'too_many_requests'">
                        {{ error.message }}
                    </cll-panel>
                </ng-container>

                <ng-container *ngIf="request.data as response">
                    <cll-panel
                        colour="info"
                        *ngIf="response.status === 'invalid_credentials'"
                        data-testid="alert:trouble-logging-in">
                        Trouble logging in?

                        <a
                            cll-button
                            cllPanelAction
                            [routerLink]="['/', 'auth', 'forgot-password']">
                            Reset your password?
                        </a>
                    </cll-panel>

                    <cll-panel
                        colour="error"
                        *ngIf="response.status === 'locked'"
                        data-testid="alert:account-locked">
                        <cll-icon cllPanelIcon>lock</cll-icon>
                        Your account has been locked. Please check your email for more information.
                    </cll-panel>

                    <cll-panel
                        colour="error"
                        *ngIf="response.status === 'expired'"
                        data-testid="alert:account-expired">
                        Your account has expired. Please contact your administrator.
                    </cll-panel>
                </ng-container>
            </ng-container>

            <button
                class="login-form-card__submit"
                cll-filled-button
                colour="secondary"
                type="submit"
                (click)="login()"
                data-testid="button:login"
                [disabled]="loginForm.invalid || (isLoggingIn$ | async) === true">
                Log in
                <cll-icon *ngIf="!showMfaChallenge">arrow_forward</cll-icon>
            </button>
        </form>
    </app-auth-form-card>

    <app-auth-form-card
        *ngIf="showMfaChallenge"
        [title]="'Multi-factor authentication'"
        [subTitle]="' Provide a multi-factor authentication code from your Authenticator app.'">
        <mat-form-field appearance="outline">
            <mat-label> MFA Code</mat-label>

            <input
                matInput
                type="text"
                [formControl]="loginForm.controls.mfa_code"
                data-testid="input:mfa_code"
                [required]="true"/>
        </mat-form-field>

        <p>
            If you're unable to use the Authenticator app, you can use one of your recovery codes or
            <a
                class="link"
                (click)="requestOtp()"
            >request a one-time code via SMS</a
            >
        </p>
        <button
            class="login-form-card__submit"
            cll-filled-button
            colour="secondary"
            (click)="login()"
            data-testid="button:login"
            [disabled]="loginForm.invalid || (isLoggingIn$ | async) === true">
            <cll-icon *ngIf="showMfaChallenge">check</cll-icon>
            Log in
        </button>
    </app-auth-form-card>

    <a
        class="back-to-login"
        *ngIf="showMfaChallenge"
        (click)="backFromMfaChallenge()">
        <cll-icon>arrow_back</cll-icon>
        Back to login</a
    >
</ng-container>

<ng-container *ngIf="successfulLoginResponse">
    <app-auth-form-card
        data-testid="container:logged_in"
        [title]="'Logged in'"
        [showHelpContact]="false">
        <cll-panel colour="primary">
            <cll-icon cllPanelIcon>info</cll-icon>
            <ng-container *ifCountry="'GB-ENG'">
                By continuing, you acknowledge that the contents may contain Personal Confidential Data and that you are
                responsible for ensuring that you comply with the appropriate regulations.
                <br/>

                <a
                    href="https://digital.nhs.uk/data-and-information/looking-after-information/data-security-and-information-governance/nhs-and-social-care-data-off-shoring-and-the-use-of-public-cloud-services/cloud-risk-framework/dimensions-that-affect-risk#data-type"
                    target="_blank">
                    <strong>Official - Sensitive</strong>
                </a>
                information, which if disclosed unintentionally, could lead to moderate or long-term damage to
                individuals.
            </ng-container>
        </cll-panel>
        <a
            class="continue-button"
            colour="secondary"
            cll-filled-button
            data-testid="button:continue"
            [href]="successfulLoginResponse.redirect_to ?? '/'">
            <cll-icon>arrow_forward</cll-icon>
            Continue
        </a>
    </app-auth-form-card>

    <a
        class="cancel-button"
        data-testid="button:cancel"
        href="/auth/logout"
        target="_self">
        Cancel
    </a>
</ng-container>

<app-start-up-message
    (dismiss)="showStartUpMessage = false"
    *ngIf="showStartUpMessage"/>
