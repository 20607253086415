import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import type { ApiObservable } from '@shared/models/api-data';
import { ApiRequestStatus } from '@shared/models/api-data';
import { fetching } from '@shared/models/api-state/operators';
import { AppErrorHandler } from '@shared/services/error-handler/app-error-handler.service';
import { Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import type { LoginCredentials } from '../models/login-credentials';
import type { LoginResponse } from '../models/login-response';
import { LoginAttemptResult } from '../models/login-response';
import { AuthService } from '../services/auth.service';
import { SnackBarService } from '@carelinelive/ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _auth = inject(AuthService);
    private readonly _errorHandler = inject(AppErrorHandler);
    private readonly _snackBar = inject(SnackBarService);

    showStartUpMessage = true;
    showMfaChallenge = false;
    loginForm: LoginForm = new FormGroup({
        email: new FormControl<string>(null, [Validators.email, Validators.required]),
        password: new FormControl<string>(null, Validators.required),
        mfa_code: new FormControl({ value: null, disabled: true }, Validators.required),
        remember: new FormControl<boolean>(false),
    });

    showForgottenPassword = false;
    successfulLoginResponse: LoginResponse = null;
    requestingOtp = false;

    loginPayload$ = new Subject<LoginCredentials>();
    loginRequest$: ApiObservable<LoginResponse> = this.loginPayload$.pipe(
        switchMap(credentials => {
            if (!credentials.mfa_code) {
                this.showMfaChallenge = false;
            }
            return this._auth.login(credentials);
        }),

        map(request => {
            const errorResponse = request.error;

            if (errorResponse instanceof HttpErrorResponse) {
                const error: LoginResponse = errorResponse.error?.data;

                if (error) {
                    this.showForgottenPassword = true;

                    const status = error.status;

                    if (status !== LoginAttemptResult.success && status !== LoginAttemptResult.mfaChallenge) {
                        this._snackBar.openError(error.message, {
                            testId: 'snackbar:login-error',
                        });

                        if (status === LoginAttemptResult.invalidCredentials) {
                            this.loginForm.controls.email.setErrors({
                                customError: 'Invalid email or password',
                            });
                        }
                    }

                    if (status === LoginAttemptResult.mfaChallenge) {
                        this.loginForm.controls.mfa_code.enable();
                        this.showMfaChallenge = true;
                    }

                    return ApiRequestStatus.withData(error);
                }
            }

            return request;
        }),
        shareReplay(1),
    );

    isLoggingIn$ = this.loginRequest$.pipe(fetching(), shareReplay(1));

    public showRememberMe: boolean = window.CareLineLive.remember?.enabled ?? true;

    get ssoEnabled(): boolean {
        return false;
        // return window.CareLineLive.sso?.enabled ?? false;
    }

    constructor() {
        this.loginForm.controls.password.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
            this.loginForm.controls.email.setErrors(null);
        });

        this.loginRequest$
            .pipe(
                takeUntilDestroyed(),
                filter(response => response.successful),
                map(response => response.data),
                filter(response => response.status === LoginAttemptResult.success),
            )
            .subscribe(response => {
                if (response.status === LoginAttemptResult.success) {
                    this.successfulLoginResponse = response;
                } else {
                    this.successfulLoginResponse = null;
                }
            });
    }

    login() {
        this.loginPayload$.next(this.buildCredentials());
    }

    requestOtp() {
        if (this.requestingOtp) {
            return;
        }

        const progress = this._snackBar.openProgress({
            title: 'Requesting OTP code',
            testId: 'snackbar:requesting-otp',
        });

        this._auth
            .requestOtp(this.buildCredentials())
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: () => {
                    this.requestingOtp = false;

                    progress.dismiss();

                    this._snackBar.openSuccess('Code requested, it may take a couple of minutes to arrive', {
                        testId: 'snackbar:code-requested',
                    });
                },
                error: error => {
                    this.requestingOtp = false;
                    progress.dismiss();
                    this._errorHandler.handle(error);
                },
            });
    }

    private buildCredentials(): LoginCredentials {
        return {
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
            remember: this.loginForm.value.remember,
            mfa_code: this.loginForm.value.mfa_code,
        };
    }

    public backFromMfaChallenge() {
        this.showMfaChallenge = false;
        this.loginForm.controls.mfa_code.disable();
    }
}

type LoginForm = FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
    mfa_code: FormControl<string | null>;
    remember: FormControl<boolean | null>;
}>;
