import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { formatDate, toDateTime } from '@shared/utils/date-time';
import type { DateTime } from 'luxon';

@Pipe({ name: 'appDate' })
export class AppDatePipe implements PipeTransform {
    transform(value: string | DateTime | Date, format?: string): any {
        const instance = toDateTime(value);

        if (instance?.isValid) {
            return formatDate(instance, true, format);
        }

        return value;
    }
}
