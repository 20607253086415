import { Component, EventEmitter, inject, Output } from '@angular/core';
import { CollectionNoticeDialogComponent } from '../../dialog/collection-notice-dialog/collection-notice-dialog.component';
import { DialogService } from '@mastechnicaegroup/carelinelive-ui';

@Component({
    selector: 'app-start-up-message',
    templateUrl: './start-up-message.component.html',
    styleUrls: ['./start-up-message.component.scss'],
})
export class StartUpMessageComponent {
    private readonly _dialog = inject(DialogService);

    @Output() dismiss = new EventEmitter<void>();

    protected showCollectionNoticeDialog(): void {
        CollectionNoticeDialogComponent.show(this._dialog);
    }
}
