<app-auth-shell-background/>

<div class="app-content">
    <app-auth-logo />

    <router-outlet />
</div>

<p class="copyright">
    Copyright &copy; 2024 CareLineLive. Part of MAS Group. All rights reserved.
</p>
