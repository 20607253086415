import { get as _get } from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/naming-convention
const CareLineLive = window.CareLineLive;

const config = {
    ...CareLineLive?.config ?? {},
};

export const country: string = CareLineLive?.country;

export default config;

export const debug = Boolean(config.debug || false);

export const googleMapsKey = get('googleMapsKey');

export const cm2000Enabled = get('integrations.cm2000.enabled', false);
export const hasteeEnabled = get('integrations.hastee.enabled', false);
export const abacusRedbridgeEnabled = get('integrations.abacus_redbridge.enabled', false);
export const tempaidEnabled: boolean = get('integrations.tempaid.enabled', false);
export const dynamicsGreatPlainsEnabled = get('integrations.dynamics_great_plains.enabled', false);
export const bhccEnabled = get('integrations.bhcc.enabled', false);
export const contrOccEnabled = get('integrations.controcc.enabled', false);
export const distanceUnits = config?.mileage?.units ?? 'miles';
export const distanceUnitSingular = distanceUnits === 'kilometres' ? 'kilometre' : 'mile';

export function get(path: string, defaultValue?: any): any {
    return _get(config, path, defaultValue);
}

export const company = get('company');

export const companyLatLng = (() => {
    if (company?.lat && company?.lng) {
        return { lat: company.lat, lng: company.lng };
    }
})();

export const companyName: string = window.CareLineLive?.config?.company?.name;
