import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppTitleService {
    currentRoute: ActivatedRouteSnapshot;

    constructor(
        private title: Title,
        private router: Router,
    ) {
        this.router.events
            .pipe(
                filter(event => event instanceof ActivationEnd),
                filter((event: ActivationEnd) => event.snapshot.data.title),
            )
            .subscribe((event: ActivationEnd) => {
                if (event instanceof ActivationEnd) {
                    this.updateTitle(
                        event.snapshot.data.title ?? event.snapshot.title
                    );
                }
            });
    }

    updateTitle(...title: string[]) {
        this.title.setTitle(
            [...title, 'CareLineLive'].filter(Boolean).join(' | ')
        );
    }
}
