import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import type { ApiRequestStatus } from '@shared/models/api-data';
import { toApiObservable } from '@shared/models/api-state/operators';
import { AppErrorHandler } from '@shared/services/error-handler/app-error-handler.service';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import type { MessageResponse, RegisterBody, ResetPasswordBody, StatusResponse } from '../models';
import type { LoginCredentials } from '../models/login-credentials';
import type { LoginResponse } from '../models/login-response';
import { SnackBarService } from '@carelinelive/ui';

/* eslint-disable @typescript-eslint/naming-convention */
const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-csrf-token': window.CareLineLive.csrf_token,
};

/* eslint-enable @typescript-eslint/naming-convention */

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly _http = inject(HttpClient);
    private readonly _errorHandler = inject(AppErrorHandler);
    private readonly _snackBar = inject(SnackBarService);

    login(credentials: LoginCredentials): Observable<ApiRequestStatus<LoginResponse>> {
        return this._http.post('/auth/login', credentials, {
            headers: DEFAULT_HEADERS,
        }).pipe(
            toApiObservable(),
        );
    }

    requestOtp(credentials: LoginCredentials) {
        return this._http.post('/auth/mfa/otp', credentials, {
            headers: DEFAULT_HEADERS,
        });
    }

    resetPassword(payload: ResetPasswordBody) {
        return this._http.post('/auth/reset', payload, {
            headers: DEFAULT_HEADERS,
        }).pipe(
            this._errorHandler.dataResponseHandler<{ status: string }>(),
            tap(response => {
                if (!!response.data?.status) {
                    this._snackBar.openSuccess(response.data.status, {
                        testId: 'snackbar:password-reset-success'
                    });
                }
            }),
        );
    }

    register(formValue: RegisterBody) {
        return this._http.post<MessageResponse>('/auth/register', formValue, {
            headers: DEFAULT_HEADERS,
        }).pipe(
            this._errorHandler.dataResponseHandler(),
            tap(response => {
                if (!!response.data?.message) {
                    this._snackBar.openSuccess(response.data.message, {
                        testId: 'snackbar:register-success'
                    });
                }
            }),
        );
    }

    sendPasswordReset(email: string) {
        return this._http.post<StatusResponse>(
            '/auth/forgot-password',
            { email },
            {
                headers: DEFAULT_HEADERS,
            },
        ).pipe(
            this._errorHandler.dataResponseHandler(),
            tap(response => {
                if (!!response.data?.status) {
                    this._snackBar.open({ title: response.data.status, testId: 'snackbar:password-reset' });
                }
            }),
        );
    }

    navigateToApplication() {
        window.location.href = '/';
    }

    passwordConfirmationValidator(passwordField: string, confirmationField: string): ValidatorFn {
        return (group: UntypedFormGroup) => {
            const password = group.get(passwordField);
            const passwordConfirmation = group.get(confirmationField);

            if (passwordConfirmation.pristine) {
                return null;
            }

            if (passwordConfirmation.value === password.value) {
                return null;
            }

            return {
                [confirmationField]: 'Does not match password',
            };
        };

    }
}
