import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { MAT_LUXON_DATE_FORMATS } from '@shared/form-fields/luxon-date-adapter';
import { toDateTime } from '../../../utils/date-time';

@Pipe({ name: 'appDateShort' })
export class AppDateShortPipe implements PipeTransform {
    transform(value: any): any {
        const instance = toDateTime(value);

        if (instance?.isValid) {
            return instance.toFormat(MAT_LUXON_DATE_FORMATS.display.dateInput);
        }

        return value;
    }
}
