import { Component, Inject, Injectable } from '@angular/core';
import type { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogComponent } from '@shared/dialogs/dialog.component';
import type { ApiObservable } from '@shared/models/api-data';
import { CurrentUser } from '@shared/services/current-user/current-user.service';
import { shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
@Component({
    template: `
        <app-dialog-header>
            <mat-icon appDialogIcon *ngIf="options.icon as icon">{{ icon }}</mat-icon>
            <app-dialog-title>{{ options.title }}</app-dialog-title>
            <app-dialog-subtitle *ngIf="options.subtitle as subtitle">{{ subtitle }}</app-dialog-subtitle>
        </app-dialog-header>

        <mat-dialog-content>
            <p *ngIf="options.content" [innerHTML]="options.content | nl2br"></p>
            <p *ngIf="!options.content">Are you sure you want to continue?</p>

            <app-alert warn icon="warning" *ngIf="options.warning">
                <p [innerHTML]="options.warning | nl2br"></p>
            </app-alert>

            <hr class="app-separator">

            <p>Enter your password ({{ currentUser.value.email }}) to confirm this action.</p>

            <mat-form-field class="app-form-field"
                            appearance="outline">
                <mat-label>Confirm your password</mat-label>

                <mat-icon matPrefix
                          class="icon-light-grey">
                    vpn_key
                </mat-icon>

                <input type="password"
                       matInput
                       name="password"
                       autocomplete="false"
                       placeholder="Please confirm your password"
                       [(ngModel)]="password"
                       data-testid="input:confirm-password"
                       [ngModelOptions]="{ standalone: true }">
            </mat-form-field>

            <app-alert-api-error *ngIf="error" [error]="error" />
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button
                    mat-dialog-close
                    (click)="cancel()">
                {{ options.cancel || 'Cancel' }}
            </button>

            <button mat-raised-button
                    color="primary"
                    (click)="okay()"
                    data-testid="button:password-action"
                    [disabled]="!password || (action$ | async)?.fetching">
                <app-loading-label [loading]="(action$ | async)?.fetching">
                    {{ options.okay || 'Okay' }}
                </app-loading-label>
            </button>
        </mat-dialog-actions>
    `,
    styleUrls: ['./confirm-password-dialog.component.scss'],
})
@UntilDestroy()
export class ConfirmPasswordDialogComponent extends DialogComponent {
    public options: ConfirmData;
    public password: string;
    public action$: ApiObservable<boolean>;
    protected error: any;

    constructor(
        public dialogRef: MatDialogRef<ConfirmPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData,
        public readonly currentUser: CurrentUser
    ) {
        super();

        this.dialogRef = dialogRef;
        this.options = data;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    okay() {
        if (this.data.action) {
            this.error = null;

            this.action$ = this.action$ ?? this.data.action(new PasswordResult(this.password)).pipe(
                untilDestroyed(this),
                shareReplay(1),
            );

            this.action$.subscribe({
                next: result => {
                    if (result.successful) {
                        this.dialogRef.close(new PasswordResult(this.password));
                    }

                    if (result.error) {
                        this.action$ = null;
                        this.error = result.error;
                    }
                },
            });
        } else {
            this.dialogRef.close(new PasswordResult(this.password));
        }
    }

    /**
     * @param dialog
     * @param data
     */
    public static show(dialog: MatDialog, data: ConfirmData) {
        return dialog.open<ConfirmPasswordDialogComponent, ConfirmData, PasswordResult | false>(ConfirmPasswordDialogComponent, {
            data,
            minWidth: '320px',
            maxWidth: 480
        });
    }
}

export class PasswordResult {
    constructor(public readonly password: string) {
        if (!password?.length) {
            throw new Error('Password cannot be empty');
        }
    }
}

export interface ConfirmData {
    title: string;
    subtitle?: string;
    icon?: string;
    content?: string;
    warning?: string;
    cancel?: string;
    okay?: string;

    action?: (p: PasswordResult) => ApiObservable<boolean>;
}
